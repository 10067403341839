<template>
  <div class="app-container">
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets" style="margin-top: 5px"></i>
      <span style="margin-top: 5px">数据列表</span>
      <el-button class="btn-add" @click="addProductAttr()" size="mini">
        添加
      </el-button>
    </el-card>
    <div class="table-container">
      <el-table
        ref="productAttrTable"
        :data="list"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        v-loading="listLoading"
        border
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column label="编号" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="属性名称" width="140" align="center">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="商品类型" width="140" align="center">
          {{ $route.query.cname }}
        </el-table-column>
        <el-table-column label="可选值列表" align="center">
          <template slot-scope="scope">{{
            scope.row.attributeValues
          }}</template>
        </el-table-column>
        <el-table-column label="Localization" width="140" align="center">
          <template slot-scope="scope">{{ scope.row.localization }}</template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleUpdate(scope.$index, scope.row)"
              >编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="batch-operate-container">
      <el-select size="small" v-model="operateType" placeholder="批量操作">
        <el-option
          v-for="item in operates"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button
        style="margin-left: 20px"
        class="search-button"
        @click="handleBatchOperate()"
        type="primary"
        size="small"
      >
        确定
      </el-button>
    </div>
    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes,prev, pager, next,jumper"
        :page-size="listQuery.pageSize"
        :page-sizes="[5, 10, 15]"
        :current-page.sync="listQuery.pageNum"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { fetchList, deleteProductAttr } from '@/api/productAttr';

export default {
  name: 'productAttrList',
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 5,
        type: this.$route.query.type,
      },
      operateType: null,
      multipleSelection: [],
      operates: [
        {
          label: '删除',
          value: 'deleteProductAttr',
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      fetchList(this.$route.query.cid, this.listQuery).then((response) => {
        this.listLoading = false;
        this.list = response.data.data;
        this.total = response.data.total;
      });
    },
    addProductAttr() {
      this.$router.push({
        path: './addProductAttr',
        query: { cid: this.$route.query.cid, type: this.$route.query.type },
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleBatchOperate() {
      if (this.multipleSelection < 1) {
        this.$message({
          message: '请选择一条记录',
          type: 'warning',
          duration: 1000,
        });
        return;
      }
      if (this.operateType !== 'deleteProductAttr') {
        this.$message({
          message: '请选择批量操作类型',
          type: 'warning',
          duration: 1000,
        });
        return;
      }
      const ids = [];
      for (let i = 0; i < this.multipleSelection.length; i += 1) {
        ids.push(this.multipleSelection[i].id);
      }
      this.handleDeleteProductAttr(ids);
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getList();
    },
    handleUpdate(index, row) {
      this.$router.push({ path: './updateProductAttr', query: { id: row.id } });
    },
    handleDeleteProductAttr(ids) {
      this.$confirm('是否要删除该属性', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        ids.forEach((id) => {
          deleteProductAttr(id).then((response) => {
            this.$message({
              message: '删除成功',
              type: 'success',
              duration: 1000,
            });
            this.getList();
          });
        });
      });
    },
    handleDelete(index, row) {
      const ids = [];
      ids.push(row.id);
      this.handleDeleteProductAttr(ids);
    },
  },
  filters: {},
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
